
export default {
    getUserInfo: {
        config: {
            url: 'api/getuserinfo',
            method: 'GET'
        }
    },
    getAreaLinkage: {
        config: {
            url: 'area/linkage',
            method: 'GET',
            headers: {
                tenant: '0000',
            },
        }
    }
}