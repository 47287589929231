const resistance = {
  list: {
    config: {
      url: "/groundingResistanceData/page",
      method: "POST",
    },
  },
  export: {
    config: {
      url: "/groundingResistanceData/export",
      method: "POST",
      responseType: 'blob'
    },
  },
};

export default resistance;
