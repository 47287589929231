const alarmData = {
  list: {
    config: {
      url: "/alarmData/page",
      method: "post",
    },
  },
  homelist: {
    config: {
      url: "/alarmData/homeList",
      method: "post",
    },
  },
  export: {
    config: {
      url: "/alarmData/export",
      method: "POST",
      responseType: "blob",
    },
  },
};

export default alarmData;
