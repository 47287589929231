export default {
  menus: {
    config: {
      url: "/menu/menus",
      method: "get",
      params: {
        // userId: 2
      }
    }
  },
  router: {
    config: {
      url: "/menu/router",
      method: "get",
      params: {
        // userId: 2
      }
    }
  }
};
