// 出入库
export default {
  list: {
    config: {
      url: "/lightInoutputHistory/pageList",
      method: "post"
    }
  },
  add: {
    config: {
      url: "/lightInoutputHistory/add",
      method: "post"
    }
  },
  update: {
    config: {
      url: "/lightInoutputHistory",
      method: "put"
    }
  },
  delete: {
    config: {
      url: "/lightInoutputHistory",
      method: "delete"
    }
  }
};
