const monitor = {
  list: {
    config: {
      url: '/monitoringSite/page',
      method: 'post',
    },
  },
  add: {
    config: {
      url: '/monitoringSite',
      method: 'post',
    },
  },
  update: {
    config: {
      url: '/monitoringSite',
      method: 'put',
    },
  },
  delete: {
    config: {
      url: '/monitoringSite',
      method: 'delete',
    },
  },
  query: {
    config: {
      url: '/monitoringSite/query',
      method: 'post',
    },
  },
};

export default monitor;
