const alarm = {
  list: {
    config: {
      url: "/alarmRules/page",
      method: "post",
    },
  },
  add: {
    config: {
      url: "/alarmRules",
      method: "post",
    },
  },
  update: {
    config: {
      url: "/alarmRules",
      method: "put",
    },
  },
  delete: {
    config: {
      url: "/alarmRules",
      method: "delete",
    },
  },
  query: {
    config: {
      url: "/alarmRules/query",
      method: "post",
    },
  },
  queryColumn: {
    config: {
      url: "/equipmentDataColumn/query",
      method: "post",
    },
  },
  trendlist: {
    config: {
      url: "/terndAlarmRules/page",
      method: "post",
    },
  },
  trendupdate: {
    config: {
      url: "/terndAlarmRules",
      method: "put",
    },
  },
};

export default alarm;
