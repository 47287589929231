export default {
  list: {
    config: {
      url: "/role/page",
      method: "post"
    }
  },
  add: {
    config: {
      url: "/role",
      method: "post"
    }
  },
  update: {
    config: {
      url: "/role",
      method: "put"
    }
  },
  delete: {
    config: {
      url: "/role",
      method: "delete"
    }
  },
  query: {
    config: {
      url: "/role/query",
      method: "post",
      data: {}
    }
  },
  // 给角色配置权限
  saveResource: {
    config: {
      url: "/role/saveResource",
      method: "post",
      data: {
        // menuIdList: [],
        // resourceIdList: [],
        // roleId: 0
      }
    }
  },
  // 查询角色拥有的资源id集合
  resourceList: {
    config: {
      url: "/role/resourceList",
      method: "get",
      params: {
        // roleId: 0
      }
    }
  }
};
