import _ from "underscore";
import $ from "jquery";
import intl from "react-intl-universal";

export function format(fmt, time) {
  let date = new Date(time);
  //author: meizz
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
}

export function param2Obj(url) {
  let search;
  url = url || window.location.href;
  search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}'
  );
}

// 深度比较对象
export function deepCompare(origin, target) {
  if (origin === null || target === null) {
    return origin === target;
  } else if (typeof target === "object" || typeof origin === "object") {
    if (_.isArray(target) && target.length !== origin.length) {
      return false;
    }

    if (typeof origin !== "object") {
      return false;
    }

    if (Object.keys(origin).length !== Object.keys(target).length) {
      return false;
    }

    for (let key in target) {
      if (!deepCompare(origin[key], target[key])) {
        return false;
      }
    }
    return true;
  } else {
    return origin === target;
  }
}

// 千分位
export function thousandFormat(num: number) {
  return (num + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
}

// m, n 之间随机整数
export function rnd(m, n) {
  return Math.floor(Math.random() * (n - m) + m);
}

export function guid() {
  let result = "";
  for (let i = 1; i <= 32; i++) {
    let n = Math.floor(Math.random() * 16.0).toString(16);
    result += n;
    if (i === 8 || i === 12 || i === 16 || i === 20) result += "-";
  }
  return result;
}
export function autoDownload(rep, busname) {
  const link = document.createElement('a');
  let blob = new Blob([rep.data], { type: 'application/vnd.ms-excel' });
  let fileName = busname + '.xlsx'
  // try {
  //   const disposition = rep.headers["content-disposition"];
  //   fileName = decodeURIComponent(disposition.split('filename="')[1].split('"; filename')[0]);
  // } catch (error) {

  // }

  link.style.display = 'none';
  link.href = URL.createObjectURL(blob);//创建url对象
  link.download = fileName //下载后文件名
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);//销毁url对象
}
export function download(url, params = {}) {
  let $form = $('<form target="downloadTarget"></form>');
  let $iframe = $('[name="downloadTarget"]');
  $form.attr({
    action: url,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-requested-with": "xmlhttprequest",
      tenant: "MDAwMA==",
      Authorization: "Basic bGlnaHRfd2ViOmxpZ2h0X3dlYl9zZWNyZXQ="
    }
  });
  if (!$iframe.length) {
    $("body").append(
      '<iframe name="downloadTarget" style="display:none;"></iframe>'
    );
  }
  for (let key in params) {
    let $input = $('<input type="text"/>');
    $input.attr("name", key).val(params[key]);
    $form.append($input);
    // $form.append('<input type="text" name="' + key + '" value="' + params[key] + '"/>')
  }
  $form
    .appendTo("body")
    .submit()
    .remove();
}

export function getUserInfo() {
  try {
    return JSON.parse(localStorage.getItem("USER_INFO") || "");
  } catch (error) {
    return {};
  }
}

export function getServerInfo() {
  try {
    return JSON.parse(localStorage.getItem("SERVER_INFO") || "");
  } catch (error) {
    return {};
  }
}

export function checkResource(code) {
  try {

    let sourceList: any = JSON.parse(localStorage.getItem("SOURCE_LIST") || "");

    return sourceList[code];
  } catch (error) {
    return false;
  }
}

export function getFirstPage() {
  let tmp = localStorage.getItem("ROLE_LIST");
  if (!tmp) {
    return ""
  }
  let roleList: any = JSON.parse(tmp);
  for (let index = 0; index < roleList.length; index++) {
    if (roleList[index].children && roleList[index].children.length > 0) {
      return roleList[index].children[0].path;
    }
  }

  return "/page"
}

export function checkMenus(menuList, path) {
  try {

    let roleList: any = JSON.parse(localStorage.getItem("ROLE_LIST") || "");

    const rolePaths: any = []
    const getRoleMenuPaths = (data) => {
      data.forEach(m => {
        rolePaths.push(m.path)
        getRoleMenuPaths(m.children || [])
      })
    }
    getRoleMenuPaths(roleList)

    const getMenus = (filters) => {
      const loop = (data) => {
        const temp: any[] = []
        for (let item of data) {
          if (!filters.includes(item.path)) continue
          const tempItem = { ...item }
          if (item.children) {
            tempItem.children = loop(item.children)
          }
          temp.push(tempItem)
        }
        return temp
      }
      const menus: any[] = loop(menuList)

      return menus
    }

    const sureList: any = getMenus(rolePaths);
    return sureList;
  } catch (error) {
    return [];
  }
}

export function getLang() {
  try {
    return localStorage.getItem("local_lang") || "zh-CN";
  } catch (error) {
    return {};
  }
}

export function serilizeUrl<T>(url) {
  var urlObject = {};
  if (/\?/.test(url)) {
    var urlString = url.substring(url.indexOf('?') + 1)
    var urlArray = urlString.split("&");
    for (var i = 0; i < urlArray.length; i++) {
      var urlItem = urlArray[i];
      var item = urlItem.split('=');
      urlObject[item[0]] = item[1];
    }
    return urlObject as T;
  }
  return null;
}

export function localFixed(value, number) {
  var res = value.toFixed(number);
  if (Number(res) == 0) {
    return 0;
  }
  return res;
}

export function getWindDirection(degree) {

  if (!degree) {
    return ""
  }

  degree = Number(degree)
  if (degree < 22.50 || degree > 337.5) {
    return intl.get("direction-north")
  }

  if (degree > 22.5 && degree < 67.5) {
    return intl.get("direction-northeast")
  }

  if (degree > 67.50 && degree < 112.50) {
    return intl.get("direction-east")
  }

  if (degree > 112.50 && degree < 157.50) {
    return intl.get("direction-southeast")
  }

  if (degree > 157.50 && degree < 202.5) {
    return intl.get("direction-south")
  }

  if (degree > 202.50 && degree < 247.5) {
    return intl.get("direction-southwest")
  }

  if (degree > 247.50 && degree < 292.5) {
    return intl.get("direction-west")
  }

  if (degree > 292.50 && degree < 337.5) {
    return intl.get("direction-northwest")
  }

  return "";
}

export function throttle(fn: () => void, time) {
  var canrun = true;//通过闭包保存一个标记
  return function (...args: any) {//返回一个函数
    if (!canrun) return;//如果不在执行时间内 则不执行
    canrun = false;//如果在执行时间内 马上将标记改为false 然后执行后续代码
    setTimeout(() => {
      // 执行时间结束时 执行函数 并且将标记设置为可执行
      fn();
      canrun = true;
    }, time);
  };
}