const tenant = {
  query: {
    config: {
      url: '/tenant/query',
      method: 'post',
    },
  }
};

export default tenant;
