import { message } from 'antd'
import axios from 'axios'
import qs from 'qs'
import history from '@/router/history'
import { getUserInfo, getLang, throttle } from '@/utils/util'
import http from '@/http'

interface UserInfo {
  userId?: string
  token?: string
  tenant?: { code: '' }
}

export const baseURL = '/light'
export const Authorization = 'Basic bGlnaHRfd2ViOmxpZ2h0X3dlYl9zZWNyZXQ='
let isLoopLogin = false
// create an axios instance

const loopLoginHanler = throttle(() => {
  const account = localStorage.getItem('ACCOUNT')
  const password = localStorage.getItem('PASSWORD')

  if (account && password) {
    http('login', 'login', {
      data: {
        account: atob(account),
        password: atob(password),
        grantType: 'password'
      }
    })
      .then((res) => {
        localStorage.setItem('USER_INFO', JSON.stringify(res))
        window.location.reload()
      })
      .catch()
  } else {
    history.push('/login?redirect=' + window.location.href)
  }
}, 1000)
const request = axios.create({
  // baseURL: process.env.BASE_API, // api的base_url
  baseURL,
  timeout: 180000, // request timeout
  headers: {
    'Content-Type': 'application/json',
    'x-requested-with': 'xmlhttprequest',
    // tenant: "0000" //  MDAwMA==
    Authorization
  }
})

// request interceptor
request.interceptors.request.use(
  (config) => {
    const lang = getLang()
    const USER_INFO: UserInfo = getUserInfo()
    const { tenant, token } = USER_INFO
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      config.data = qs.stringify(config.data, {
        arrayFormat: 'indices',
        allowDots: true
      })
    }

    if (!lang || lang == 'zh-CN') {
      config.headers['Accept-Language'] = 'zh-CN' + ',zh;q=0.8'
    } else {
      config.headers['Accept-Language'] = 'en-US' + ',en;q=0.5'
    }

    if (tenant) {
      config.headers.tenant = tenant.code
    }
    if (token) {
      config.headers.token = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// respone interceptor
request.interceptors.response.use(
  (response) => {
    /**
     * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
     * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
     */
    const res = response.data
    const { data, code, msg } = res

    if (code === 0) {
      return data
    }
    if (code === undefined) {
      return response
    }

    msg && message.error(msg)
    return Promise.reject('error')
  },
  (error) => {
    try {
      const { status, data } = error.response
      const { code, msg, errorMsg } = data
      const errMsg = errorMsg || msg || data

      if ([401, 404].includes(status)) {
        loopLoginHanler()
      }

      if (errMsg && typeof errMsg === 'string') {
        message.error(errMsg)
      }
    } catch (error) {}
    return Promise.reject(error)
  }
)

export default request
