const mete = {
  atmosList: {
    config: {
      url: "/micAtmosData/page",
      method: "POST",
    },
  },
  atmosExport: {
    config: {
      url: "/micAtmosData/export",
      method: "POST",
      responseType: 'blob'
    },
  },
  windList: {
    config: {
      url: "/micWindData/page",
      method: "POST",
    },
  },
  windExport: {
    config: {
      url: "/micWindData/export",
      method: "POST",
      responseType: 'blob'
    },
  },
};

export default mete;
