const gatewayMsg = {
  list: {
    config: {
      url: '/gatewayMsg/page',
      method: 'post',
    },
  },
  export: {
    config: {
      url: "/gatewayMsg/export",
      method: "POST",
      responseType: 'blob'
    },
  },
};

export default gatewayMsg;
